#overlay {
    background: rgba($color: #000000, $alpha: 0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    transition: background $mainTransition;

    &.active {
        background: rgba($color: #000000, $alpha: 0.4);
        z-index: 12001;
        visibility: visible;

        &.z-index {
            z-index: 12202;
        }
    }

    &.overlay-transparent {
        background-color: transparent;
    }

    @include media-breakpoint-up(md) {
        &.transparent {
            background-color: transparent;
            z-index: 4;
        }
    }
}

.sidebar {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: -60px;
    left: 0;
    background: #FFFFFF;
    border-radius: 0.75rem 0.75rem 0px 0px;
    z-index: 12203;
    transform: translateY(100%);
    transition: transform $mainTransition, opacity 0.15s linear 0.1s;
    overflow: hidden;

    &.active {
        transform: translateY(0);
        bottom: 0;
        opacity: 1;
    }

    [data-sidebar="close"] {
        position: absolute;
        z-index: 2;
        right: 0.75rem;
        top: 0.5rem;
    }

    &-header {
        background: #F2F4F8;
        transition: background $mainTransition;
        height: 56px;

        &-menu {
            background: #FFFFFF;

            >* {
                pointer-events: none;
            }
        }

        .sc-btn-secondary {
            flex: 0 0 auto;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            filter: none;
        }
    }

    &-inner {
        height: 100%;
        max-height: calc(100% - 56px);
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;

        &-cart {
            max-height: calc(100% - 198px);
        }
    }

    &-main-menu {

        &-item {
            border-bottom: 1px solid #F2F4F8;

            &-icon {
                background-color: $primaryLightColor;

                &:before {
                    content: '';
                    mask-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    background: $darkTextColor;
                    display: block;
                    transition: background $mainTransition;
                }
            }

            &-catalog {
                .sidebar-main-menu-item-icon {
                    background-color: $primaryColor;

                    &:before {
                        mask-image: url(../img/sprite.svg#include--btn-catalog-icon);
                        background: #FFFFFF;
                    }
                }
            }

            &-account {
                .sidebar-main-menu-item-icon {
                    &:before {
                        mask-image: url(../img/sprite.svg#include--top-account-icon);
                    }
                }
            }

            &-viewed {
                .sidebar-main-menu-item-icon {
                    &:before {
                        mask-image: url(../img/sprite.svg#include--header-btns-viewed);
                    }
                }

                .sc-module {
                    margin: 0 !important;
                    background: transparent;
                    padding: 0 1rem 1rem !important;

                    &-item {
                        @include media-breakpoint-up(md) {
                            flex: 0 0 50%;
                            width: 50%;
                        }
                    }

                    &-header {
                        display: none !important;
                    }

                    &-img {
                        flex: 0 0 100px !important;
                        max-width: 100px !important;
                        align-self: center;
                    }

                    &-caption {
                        flex: 0 0 calc(100% - 100px) !important;
                        max-width: calc(100% - 100px) !important;
                        padding-left: 1rem !important;
                    }

                    &-model,
                    &-rating,
                    &-quantity,
                    &-stickers {
                        display: none !important;
                    }

                    &-price-box {
                        padding-bottom: 0 !important;
                        padding-right: 3rem !important;
                        display: flex !important;
                        flex-direction: column !important;

                        .price-tax {
                            margin-bottom: 0.25rem;
                        }
                    }

                    &-cart {
                        flex: 0 0 36px !important;
                        max-width: 36px !important;
                        position: absolute;
                        right: 1rem;
                        bottom: 1rem;

                        .sc-btn-primary,
                        .sc-btn-text {
                            display: none;
                        }
                    }
                }

                .content-block {
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    position: relative;

                    @include media-breakpoint-down(md) {
                        height: auto !important;
                    }
                }
            }

            &-chevron img,
            &-phones img {
                object-fit: cover;
            }

            .sc-switcher {
                margin-left: 0 !important;
            }
        }
    }

    &-messengers {
        &-item {
            height: 40px;
            width: 40px;
            flex: 0 0 auto;
            border-radius: 20px;

            &:before {
                content: '';
                mask-repeat: no-repeat;
                width: 20px;
                height: 20px;
                background: #FFFFFF;
                display: block;
            }

            &-messenger {
                background-color: #3D9AF0;

                &:before {
                    mask-image: url(../img/sprite.svg#include--messenger-icon);
                }
            }

            &-viber {
                background-color: #763DF0;

                &:before {
                    mask-image: url(../img/sprite.svg#include--viber-icon);
                }
            }

            &-telegram {
                background-color: #5BC7F5;

                &:before {
                    mask-image: url(../img/sprite.svg#include--telegram-icon);
                }
            }

            &-whatsapp {
                background-color: #64CE69;

                &:before {
                    mask-image: url(../img/sprite.svg#include--whatsapp-icon);
                }
            }

            &-skype {
                background-color: #31c4ed;

                &:before {
                    mask-image: url(../img/sprite.svg#include--skype-icon);
                }
            }
        }
    }

    &-socials {
        &-item {
            height: 39px;
            width: 39px;
            flex: 0 0 auto;
            border-radius: 20px;
            background: #F4F4F4;
            border: 1px solid #E9E9E9;

            &+& {
                margin-left: 0.5rem;
            }
        }
    }

    &-menu-toggle {
        a>* {
            pointer-events: none;
        }
    }

    &-menu-catalog {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #F2F4F8;
        z-index: 2;
        transform: translateX(100%);
        transition: transform $mainTransition;

        &.active {
            transform: translateX(0);
        }

        &-inner {
            height: calc(100% - 56px);
            overflow-x: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;

            >ul>li {
                &:not(:first-child) {
                    border-top: 1px solid #F2F2F2;
                }

                &.sidebar-menu-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        pointer-events: none;
                        content: '';
                        background: url(../img/sprite.svg#include--location-chevron) no-repeat;
                        width: 10px;
                        height: 10px;
                        transform: rotate(-90deg);
                        background-size: cover;
                    }
                }
            }
        }
    }

    &-search {
        .header-search {
            background: #FFFFFF;
        }

        .header-search .form-control {
            padding-top: 0.675rem;
            padding-bottom: 0.675rem;
        }

        .header-search .sc-btn-with-icon {
            right: 0.25rem;
            top: 25%;
        }

        .content-block {
            padding: 0.5rem;

            &:not(:first-child) {
                margin-top: 0.5rem;
            }
        }

        .sc-module-cart .sc-btn-primary {
            right: 0.5rem !important;
            bottom: 0.5rem !important;
        }
    }

    &-account {
        .content-block {
            box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        }
    }

    &-cart {
        &-item {
            &+& {
                margin-top: 0.5rem;
            }

            &-left {
                flex: 0 0 90px;
                max-width: 90px;
            }

            &-title a {
                color: $darkTextColor !important;

                &:hover {
                    color: $linksColor !important;
                    text-decoration: none;
                }
            }

            &-price {
                white-space: nowrap;
            }
        }

        &-bottom {
            background: #FFFFFF;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.sc-location {
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

    &+& {
        margin-top: 1rem;
    }

    .sc-btn-secondary {
        flex: 0 0 auto;
        width: 20px;
        height: 20px;

        .sc-btn-icon-chevron {
            mask-image: url(../img/sprite.svg#include--location-chevron);
            width: 10px;
            height: 10px;
        }
    }

    &-phones {
        display: flex;
        flex-direction: column;
    }

    &-collapsed {
        max-height: 0;
        opacity: 0;
        transition: max-height $mainTransition, padding-top $mainTransition, opacity $mainTransition 0.15s;
        overflow: hidden;
    }

    iframe {
        max-width: 100%;
        border-radius: 0.25rem !important;
        max-height: 320px !important;
    }

    &.active {
        .sc-btn-secondary {
            background: $primaryColor;

            .sc-btn-icon {
                background: #FFFFFF;
                transform: rotate(180deg);
            }
        }

        .sc-location-collapsed {
            max-height: 800px;
            padding-top: 1rem;
            opacity: 1;
        }
    }
}