.ocf {
    &-container {
        @include content-block;

        @include media-breakpoint-down(xl) {
            padding: 0;
        }
    }

    &-header {
        letter-spacing: .01em;
        font-size: 1.375rem;
        font-weight: 700;
        color: $darkTextColor;
    }
    @include media-breakpoint-up(xl) {
        &-content {
            margin-bottom: 0 !important;
        }

        &-footer {
            padding-bottom: 0 !important;
        }
    }

    &-selected-header {
        color: $darkTextColor;
    }

    @include media-breakpoint-between(xl, xxl) {
        &-between {
            flex-direction: column;
        }
    }

    &-description {
        @include content-block();
        
        &-top {
            margin-bottom: 1rem;
        }

        &-bottom {
            margin-top: 1rem;
        }
    }
}