.sc-account {
    &-row {
        #column-left,
        #column-right {
            display: block !important;
        }

        #column-right {
            @include media-breakpoint-down(xl) {
                margin-top: 1rem;
            }
        }
    }

    &-module {
        .sc-column-categories-item {
            transition: background-color $mainTransition;

            > span {
                padding: 0.75rem 1.5rem;
                line-height: 17px;
            }

            &:hover {
                background-color: #FCFCFC;
            }

            & >:not(ul) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }

        &-icon {
            width: 40px;
            height: 40px;
            display: grid;
            place-items: center;
            background: #F2F2F5;
        }
    }

    &-login-button {
        width: 190px;
    }

    &-wishlist-button {
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 2rem;
        }
    }

    &-row {
        .form-control {
            @include media-breakpoint-up(md) {
                max-width: 400px;
            }
        }
    }

    &-table {
        td,
        th {
            max-width: 300px;
        }
    }
}

#account-wishlist {
    .sc-module-cart-btn {
        min-width: 180px;
    }
}

.accordion-button:not(.collapsed) {
    color: $darkTextColor;
    font-weight: 600;
    font-size: 18px;
    background-color: #f6f6f6;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

#oct_stock_notifier,
#account-order {
    .pagination {
        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            padding-left: 0;
        }
    }
}