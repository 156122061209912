.body-product {
    @include media-breakpoint-down(md) {
        padding-top: 88px;
        
        #top {
            position: fixed;
            inset: 0 0 auto;
        }

        #sc_fixed_contact_button {
            bottom: 120px;
        }

        #back-top {
            bottom: 204px;
        }
        
        .tooltip {
            z-index: 1001;
        }
    }

    @media (max-width: 360px) {
        #sc_fixed_contact_button {
            bottom: 100px;
        }

        #back-top {
            bottom: 184px;
        }
    }
}

.sc-product {

    &-tabs {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
        overflow: hidden;
        overflow-x: auto;

        &-box {
            @include media-breakpoint-down(md) {
                position: fixed;
                z-index: 1020;
                top: 48px;
                left: 0;
                right: 0;
                padding: 0;
            }

            @include media-breakpoint-up(md) {
                top: 50px;
                z-index: 1021;
            }
        }

        @include media-breakpoint-up(md) {
            border-radius: 0.5rem;
        }
    }

    &-tab {
        border-bottom: 3px solid transparent;
        transition: border-bottom-color $mainTransition;
        padding: 0.5rem 0.75rem;
        cursor: pointer;

        & > * {
            pointer-events: none;
        }

        @include media-breakpoint-up(md) {
            padding: 1.25rem 2.5rem;
        }

        @include media-breakpoint-down(xl) {
            img {
                display: none;
            }
        }

        & + & {
            border-left: $mainBorder;
        }

        &-title {
            letter-spacing: 0.2px;
            white-space: nowrap;
            color: $lightTextColor;
        }

        &.active {
            border-bottom-color: $primaryColor;
            
            .sc-product-tab-title {
                font-weight: 700;
                color: $darkTextColor;
            }
        }

        &-badge {
            width: 1rem;
            height: 1rem;
            background: #000000;
            color: #FFFFFF;
        }

        @include media-breakpoint-down(xl) {
            &:hover {
                border-bottom-color: $primaryColor;
            }
        }
    }

    &-images {
        overflow: hidden;
        padding-bottom: 1rem;
        @include media-breakpoint-down(sm) {
            min-height: 442px;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 1.875rem;
            top: 112px;
        }

        .sc-module-stickers-left {
            flex-wrap: wrap;
            gap: 0.25rem;
        }

        &-main {
            @include media-breakpoint-up(xl) {
                height: 600px;
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                padding: 0;
                
                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    min-height: 24px;
                    
                    li {
                        padding: 0.5rem 0.375rem;
                    }

                    button {
                        margin-right: 0;
                    }
                }
            }

            img {
                max-width: 100%;
                block-size: auto;
            }

            > div {
                display: none;
                
                &:nth-child(1) {
                    display: block;
                }
            }

            .zoomImg {
                @include media-breakpoint-down(md) {
                    display: none !important;
                }
            }
        }

        &-additional {
            padding: 1.875rem 4.25rem 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease;
            min-height: 110px;

            &.slick-initialized {
                visibility: visible;
                opacity: 1;
            }

            @include media-breakpoint-up(md) {
                border-top: $mainBorder;
            }

            .slick-list {
                min-width: 100%;
                
                @include media-breakpoint-up(xxl) {
                    padding-right: 0.25rem;
                }
            }

            .slick-slide {
                border: 1px solid transparent;
                padding: 0.5rem;
                min-width: 78px;

                & + & {
                    margin-left: 0.25rem;
                }

                img {
                    display: block;
                    margin: 0 auto;
                }

                &.slick-current {
                    border: $mainBorder;
                }
            }

            .slick-slider-btn {
                top: calc(50% + 1rem);
                box-shadow: none;
                filter: none;
                border: 1px solid #f2f4f8;
            }

            .slick-slider-btn[aria-disabled=true] {
                opacity: 1;
            }

            .slick-slider-btn-prev {
                left: 1rem;
            }

            .slick-slider-btn-next {
                right: 1rem;
            }
        }
    }

    &-info {
        @include media-breakpoint-down(md) {
            padding-left: 1rem;
            padding-right: 1rem;
    }

        &-item {
            background: #F2F4F8;

            &-badge {
                padding: 0.125rem 0.25rem;
                background: #FFFFFF;
                line-height: 15px;
            }
        }
    }

    &-actions {
        &-top {
            &.with-border {
                border-bottom: $mainBorder;
            }

            &-left {
                .sc-btn-secondary {
                    padding: 0.5rem 0.75rem;
                    line-height: 22px;
                }
            }

            &-right {
                .sc-btn {
                    padding: 0.625rem;
                    filter: none;
                }
            }
        }

        .sc-buy-one-click {
            border-top: $mainBorder;

            &-title {
                @include media-breakpoint-down(sm) {
                    line-height: 1;
                }

                @include media-breakpoint-up(sm) {
                    flex: 0 0 152px;
                    max-width: 152px;
                }
            }

            .form-control {
                height: 48px;

                @include media-breakpoint-up(sm) {
                    max-width: 12rem;
                }
            }

            .sc-btn {
                height: 48px;
                width: 48px;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                position: relative;
                left: 0px;
            }
        }
    }

    &-timer {
        &-inner {
            color: $redColor;

            > * {
                &.animated {
                    animation: seconds 1s infinite;
                }
            }
        }

        &-number {
            width: 1.5rem;
            text-align: center;
        }

        &-delimiter {
            padding: 0 0.125rem;
        }
    }

    &-options {
        @include media-breakpoint-up(sm) {
            .sc-control-label {
                width: 152px;
                flex: 0 0 152px;
            }
        }

        input[type="text"]:not([name="quantity"]) {
            height: 48px;
            line-height: 48px;
        }

        .form-group + .form-group {
            margin-top: 1rem;
        }

        .options-box {
            gap: 0.5rem;
            flex-wrap: wrap;
        }

        .radio {
            label {
                margin: 0;
                padding: 10px 17px;
                color: $darkTextColor;
                font-size: 12px;
                line-height: 15px;
                height: 35px;
                background: #F2F4F8;
                border: 1px solid #EBEBF0;
                border-radius: 4px;
                cursor: pointer;
                transition: background $mainTransition, border $mainTransition, color $mainTransition;
                white-space: nowrap;

                &.selected {
                    background: $primaryColor;
                    color: #FFFFFF;
                }

                &.radio-img {
                    border: 2px solid #F2F2F5;
                    border-radius: 20px;
                    width: 35px;
                    padding: 0;

                    &.selected {
                        border: 2px solid $primaryColor;
                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 16px;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &-buttons {
        .sc-module-quantity {
            margin-right: 1rem;
            flex: 0 0 136px;

            @include media-breakpoint-up(sm) {
                margin-right: 1.5rem;
                flex: 0 0 128px;
            }

            @include media-breakpoint-up(lg) {
            }
        }

        .sc-btn {
            padding: 0.875rem 1.25rem;

            @include media-breakpoint-up(sm) {
                padding: 0.875rem 4rem;
            }
        }
    }

    &-attr {
        line-height: 17px;

        &-list {
            background: #F2F4F8;

            &-item {
                padding: 0.875rem 1.25rem;

                &:nth-child(even) {
                    background: #FDFDFD;
                }

                > div {
                    flex: 0 0 50%;
                    max-width: 50%;

                    &:first-child {
                        color: $darkTextColor;
                    }

                    &:last-child {
                        padding-left: 1rem;
                        color: $secondaryTextColor;
                    }
                }
            }
        }
    }

    &-content {

        @include media-breakpoint-up(lg) {
            margin-top: 1rem;
        }

        &-left {
            border-right: $mainBorder;
            background: #FFFFFF;
        }

        &-right {
            @include media-breakpoint-up(lg) {
                background: #FFFFFF;
            }
        }

        &-title {
            line-height: 29px;
            
            &-icon {
                width: 26px;
                height: 26px;
                background: $primaryLightColor;
                margin-right: 0.75rem;
            }
        }

        &-description,
        &-attributes,
        &-reviews,
        &-faq {
            border-bottom: $mainBorder;
        }

        p + p,
        p + ul {
            margin-top: 1rem;
        }

        &-attributes {
            &-list {
                & + & {
                    margin-top: 1rem;
                }

                &-title {
                    font-weight: 700;
                    line-height: 24px;
                    color: $darkTextColor;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                }

                &-item {
                    background: #FFFFFF;
                    flex-direction: row;font-size: 14px;

                    &:nth-child(even) {
                        background: $primaryLightColor;
                    }

                    > div {
                        padding: 14px 20px;
                        width: 50%;

                        &:first-child {
                            font-weight: 500;
                        }

                        &:last-child {
                            color: $secondaryTextColor;
                        }
                    }
                }
            }
        }

        &-reviews {
            &-rating-item {
                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
            }

            &-summary {
                .sc-product-content-reviews-rating-stars {
                    flex-direction: row-reverse;
                }
            }

            &-rating-stars {
                padding: 2px;
                gap: 2px;
                background: #F8F9FA;
                border-radius: 0.25rem;
            }

            &-rating-line {
                height: 6px;
                background: #F5F5F5;
                border-radius: 0.25rem;
                margin: 0 1rem;
                width: 100%;

                @media (max-width: $break-xxs) {
                    flex-grow: 1;
                }

                @media (min-width: $break-xs) {
                    width: 300px;
                }

                @include media-breakpoint-between(lg, xl) {
                    width: 226px;
                }

                span {
                    display: block;
                    height: 100%;
                    background: #C7C9D9;
                    border-radius: 0.25rem;
                }
            }

            &-rating-value {
                background: #f8f9fa;
                padding: 2px 6px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                border-radius: 2px;
                flex-grow: 1;
                flex-basis: 0;
                max-width: 44px;
                min-width: 22px;
            }

            &-item {
                border: $mainBorder;
                @include media-breakpoint-down(md) {
                    background: #FFFFFF;
                }

                & + & {
                    margin-top: 1rem;
                }

                &-answer {
                    background: $primaryLightColor;
                    
                    p {
                        color: $secondaryTextColor;
                    }
                }
            }
        }

        b {
            font-weight: 600;
        }

        ul {
            margin-left: 1rem;
        }

        &-advantages {
            @include media-breakpoint-between(md, lg) {
                max-width: 100%;
                overflow-y: auto;
            }

            &-item {
                padding: 0.5rem 1rem;
                background: #FFFFFF;
                border-bottom: $mainBorder;

                @include media-breakpoint-down(md) {
                    & + & {
                        margin-top: 1rem;
                    }
                }

                @include media-breakpoint-up(md) {
                    border-radius: 0.25rem;
                    border: $mainBorder;

                    & + & {
                        margin-left: 1rem;
                    }
                }

                @include media-breakpoint-between(md, lg) {
                    width: calc(33.3333% - 0.75rem);
                    flex: 0 0 calc(33.3333% - 0.75rem);
                }
                
                @include media-breakpoint-up(lg) {
                    padding: 1rem;

                    & + & {
                        margin-top: 1rem;
                        margin-left: 0;
                    }
                }

                &-title {
                    padding-left: 0.75rem;
                    letter-spacing: 1px;
                }
            }
        }

        &-card {
            width: 100%;
            top: 138px;

            .sc-module-btns {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &-tags {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-fixed-btns {
        background: #FFFFFF;
        right: 0;
        bottom: 32px;
        left: 0;
        z-index: 1000;
        transform: translateY(100%);
        transition: transform $mainTransition;

        @media (min-width: $break-xxs) {
            bottom: 55px;
        }

        &.enabled {
            transform: translateY(0);
            border-top: $mainBorder;
        }

        .sc-btn {
            width: auto;
            height: 32px;
            padding: 0.5rem 1rem;
            margin-right: 1px;

            @media (max-width: $break-xxs - 1) {
                &.sc-product-fixed-cart-btn {
                    .sc-btn-text {
                        display: none;
                    }
                }
            }

            &-icon {
                width: 16px;
                height: 16px;
            }
        }

        & + footer {
            padding-bottom: 48px;
        }
    }
}

.sc-empty-reviews {
    border: $mainBorder;
    background: #FBFBFB;
}

@keyframes seconds {
    0% {
        opacity: 0
    }

    25% {
        opacity: 1
    }


    75% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}